import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { createTransaction } from '../services/userService';


const PaymentPage = () => {
  // Custom hook to parse URL parameters
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();

  const decodeBase64 = (encoded) => {
    try {
      return atob(encoded); // Decodes Base64 string
    } catch (error) {
      console.error("Invalid Base64 string:", encoded);
      return null;
    }
  };

  const processDecodedParams = async (decodedParams) => {
    console.log(decodedParams)
      const response = await createTransaction({"profile_id":decodedParams.profileId,"amount":decodedParams.amount,"status":false,"plan_id":decodedParams.plan,"access_months":decodedParams.tenure});
      console.log(response.data.data.instrumentResponse.redirectInfo.url);
      window.open(response.data.data.instrumentResponse.redirectInfo.url, "_blank"); 
  };

  useEffect(() => {
    const profileId = query.get('profileId');
    const amount = query.get('amount');
    const plan = query.get('plan');
    const tenure = query.get('tenure');

    if (profileId && amount && plan && tenure) {
      const decodedParams = {
        profileId: profileId,
        amount: parseFloat(decodeBase64(amount)),
        plan: plan,
        tenure: decodeBase64(tenure) == "Yearly"?12:3,
      };

      processDecodedParams(decodedParams);
    } else {
      console.error("One or more parameters are missing in the URL.");
    }
  }, []); // Runs once on component mount

  return (
    <div style={styles.container}>
      <h1>Processing Payment</h1>
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    marginTop: '50px',
    fontFamily: "'Arial', sans-serif",
  },
};

export default PaymentPage;
